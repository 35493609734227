import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby-plugin-react-intl'

type ButtonStyledProps = {
    fullWidth?: boolean
    color: 'primary' | 'secondary' | 'default'
    padding?: 'no-margin' | 'large' | 'normal' | 'small'
    disabled?: boolean
}

export const ButtonStyled = styled.a<ButtonStyledProps>((props) => {
    const backgroundColors = {
        primary: props.theme.color.brand.primary[500],
        secondary: props.theme.color.brand.secondary[900],
        default: props.theme.buttons.primary.background,
    }
    const foreColor = {
        primary: props.theme.buttons.primary.foreground,
        secondary: props.theme.buttons.secondary.foreground,
        default: props.theme.buttons.secondary.foreground,
    }

    const paddings = {
        'no-margin': props.theme.spacing(0),
        large: props.theme.spacing(2, 0, 2, 0),
        normal: props.theme.spacing(3, 4),
        small: props.theme.spacing(2, 0, 2, 0),
    }

    return {
        textDecoration: 'none',
        fontSize: props.theme.spacing(2),
        fontWeight: 700,
        padding: paddings[props.padding ?? 'normal'],
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: props.fullWidth ? '100%' : 'fit-content',
        backgroundColor: props.disabled
            ? props.theme.buttons.disabled.background
            : backgroundColors[props.color],
        color: props.disabled ? props.theme.buttons.disabled.foreground : foreColor[props.color],
        border: 'none',
        borderRadius: props.theme.spacing(6),
        opacity: props.disabled ? 0.5 : 1,
    }
})

export const LinkStyled = styled(Link)<ButtonStyledProps>((props) => {
    const backgroundColors = {
        primary: props.theme.buttons.primary.background,
        secondary: props.theme.buttons.secondary.background,
        default: props.theme.buttons.primary.background,
    }
    const foreColor = {
        primary: props.theme.buttons.primary.foreground,
        secondary: props.theme.buttons.secondary.foreground,
        default: props.theme.buttons.secondary.foreground,
    }

    return {
        margin: props.theme.spacing(2, 0, 2, 0),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: props.fullWidth ? '100%' : 'fit-content',
        backgroundColor: props.disabled
            ? props.theme.buttons.disabled.background
            : backgroundColors[props.color],
        color: props.disabled ? props.theme.buttons.disabled.foreground : foreColor[props.color],
        border: 'none',
        borderRadius: 4,
        textDecoration: 'none',
        opacity: props.disabled ? 0.5 : 1,
    }
})

export const LabelStyled = styled.p<ButtonStyledProps>((props) => ({
    cursor: 'pointer',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    padding: props.theme.spacing(2, 3),
    margin: 0,
    [props.theme.breakpoints.down(props.theme.breakpoints.smartphone)]: {
        padding: props.theme.spacing(2, 3),
    },
}))

export const Boutton = styled.a({
    backgroundColor: '#03cea4',
    border: 'none',
    color: '#0D374D',
    borderRadius: '4px',
    padding: '16px',
    fontWeight: 600,
    display: 'inline-block',
    cursor: 'pointer',
})
